import React, { useState } from "react";
import "./App.css";
import SenimanKodeLogo from "./assets/logo-abracodebra.png";

function App() {
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = { text: inputText };

    try {
      const response = await fetch(
        "https://qr-generator.senimankode.id/generate-qr",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      // Convert the response into a blob (binary data)
      const blob = await response.blob();

      // Create a URL for the blob and set it as the QR code image source
      const qrCodeUrl = URL.createObjectURL(blob);
      setQrCode(qrCodeUrl);
    } catch (error) {
      console.error("Error generating QR code:", error);
      alert("An error occurred while generating the QR code.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-yellow-900 via-black to-green-700 text-white p-10">
      <img
        src={SenimanKodeLogo}
        alt="logo"
        className="w-[600px] animate-pulse z-10"
      />
      <div className="flex flex-col items-center">
        <h1 className="text-3xl font-extrabold mb-8 tracking-wide z-30">
          QR GENERATOR
        </h1>
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md space-y-6 z-30"
        >
          <input
            type="text"
            placeholder="Enter text or URL"
            value={inputText}
            onChange={handleInputChange}
            className="w-full px-4 py-3 rounded-lg bg-white text-black placeholder-gray-500 focus:ring-4 focus:ring-blue-500 focus:outline-none transition duration-200"
          />
          <button
            type="submit"
            className={`w-full py-3 rounded-lg text-lg font-bold ${
              loading ? "bg-gray-500" : "bg-blue-600 hover:bg-blue-700"
            } transition duration-300 ease-in-out transform hover:scale-105`}
            disabled={loading}
          >
            {loading ? "Generating..." : "Generate QR Code"}
          </button>
        </form>
      </div>
      {qrCode && (
        <div className="flex flex-col justify-items-center items-center mt-8">
          <h2 className="text-2xl font-bold mb-4">Your QR Code:</h2>
          <img
            src={qrCode}
            alt="QR Code"
            className="w-64 h-64 rounded-lg shadow-lg border-4 border-white"
          />
          <a
            href={qrCode}
            download={`${inputText.replace(/[^a-zA-Z0-9]/g, "_")}.png`}
            className="mt-4 inline-block px-6 py-3 rounded-lg text-lg font-bold bg-green-600 hover:bg-green-700 text-white transition duration-300 ease-in-out transform hover:scale-105"
          >
            Download QR Code
          </a>
        </div>
      )}
    </div>
  );
}

export default App;
